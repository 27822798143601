.HomeImage {
  position: absolute;
  top: 60%;
  left: 33%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .HomeImage {
    left: 30%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
}