/* Navbar.css */

.navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 1rem;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

@media only screen and (min-width: 768px) {
    .navbar-container {
        padding: 1.5rem 2rem;
    }
}

.logo {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    background-image: url("../../images/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 200px;
    height: 80px;
}

@media only screen and (min-width: 768px) {
    .logo {
        margin-right: 2rem;
        height: 70px;
    }
}

.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20px;
    height: 16px;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
}

@media only screen and (min-width: 768px) {
    .toggle-button {
        display: none;
    }
}

.toggle-button.active .hamburger-line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.toggle-button.active .hamburger-line:nth-child(2) {
  opacity: 0;
}

.toggle-button.active .hamburger-line:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.hamburger-line {
    width: 20px;
    height: 2px;
    background-color: #fff;
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.nav-links {
    position: absolute;
    top: 80px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(36, 35, 35, 0.2);
    border: 1px solid rgba(128, 128, 128, 0.4);
    padding: 1em 1.5em;
    box-shadow: inset 0 0 10px rgba(67, 77, 207, 0.5);
    padding: 16px;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    width: 50%;
}

.navlink-wrapper {
    flex-direction: column;
    align-items: right;
    width: 50%;
    z-index: 3;
}

@media only screen and (min-width: 768px) {
    .navlink-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: auto;
    }
    .nav-links {
        display: block;
        position: static;
        flex-direction: row;
        align-items: center;
        box-shadow: none;
        transform: none;
        opacity: 1;
        pointer-events: auto;
        background-color: transparent;
        border: 1px solid rgba(128, 128, 128, 0.4);
        padding: 1em 1.5em;
        box-shadow: inset 0 0 10px rgba(67, 77, 207, 0.5);
        width: auto;
    }
}

.nav-link {
    color: #fff;
    display: block;
    padding: 0.5rem 1rem;
    font-size: 1em;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
}

@media only screen and (min-width: 768px) {
    .nav-link {
        padding: 0 1rem;
        border-bottom: none;
        color: #fff;
    }
}

.divider {
    margin: 0.5rem 0;
    border: none;
    height: 1px;
    background-color: rgba(36, 35, 35, 0.2);
    border: 1px solid rgba(128, 128, 128, 0.4);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}
