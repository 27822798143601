/* HomePage.css */

.home-container {
  margin-bottom: 1rem;
  }

.background-image {
  height: 100vh;
  background-image: url("../../images/hero-background-alt.png");
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}